import { Box, Flex, Text, Title } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import classes from '../Course.module.css';
import { getEvents } from '../../../../api/studyFlows';
import { ReactComponent as EventIcon } from '../../../../assets/icons/EventIcon.svg';
import { bodyTextColorHex } from '../../../../theme';
import dayjs from 'dayjs';
export const eventDateFormat = 'YYYY-MM-DD, HH:mm';

const EventsList = () => {
  const { studyFlowId } = useParams();

  const { data: events } = useQuery({
    queryKey: ['events', studyFlowId],
    queryFn: async () => (studyFlowId ? getEvents(studyFlowId) : undefined),
  });

  const filtered = events?.filter(
    (event) => new Date(event.startAt).getTime() > new Date().getTime(),
  );
  return (
    <Box className={classes.pageContent} mt="0">
      <Flex mb="18px" pb="24px" className={classes.header}>
        <Title order={5} fz={20} fw="normal">
          Майбутні події
        </Title>
      </Flex>

      <div>
        {filtered?.length ? (
          filtered
            .sort(
              (a, b) =>
                new Date(a.startAt).getTime() - new Date(b.startAt).getTime(),
            )
            .map((event) => (
              <Flex
                className={classes.event}
                component="a"
                href={event.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Flex align="center" pr="8px">
                  <Flex
                    bg="#0B0B0B"
                    align="center"
                    justify="center"
                    className={classes.icon}
                  >
                    <EventIcon />
                  </Flex>
                  <Flex ml="12px" direction="column" justify="center">
                    <Title order={5} fz={12} fw="700">
                      {event.name}
                    </Title>
                    <Text className={classes.topic}>{event.topic}</Text>
                  </Flex>
                </Flex>
                <Text className={classes.date}>
                  {dayjs(new Date(event.startAt)).format(eventDateFormat)}
                </Text>
              </Flex>
            ))
        ) : (
          <>
            <Title
              order={4}
              fz={20}
              fw="normal"
              c={bodyTextColorHex}
              mb={48}
              mt={70}
              ta="center"
            >
              В цього курсу немає подій
            </Title>
          </>
        )}
      </div>
    </Box>
  );
};

export default EventsList;
