import React from 'react';
import './App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import SignUp from './pages/Anonymous/SignUp';
import SignIn from './pages/Anonymous/SignIn';
import ForgotPassword from './pages/Anonymous/ForgotPassword';
import ResetPassword from './pages/Anonymous/ResetPassword';
import {
  SING_IN_PATH,
  SIGN_UP_PATH,
  FORGOT_PASSWORD_PATH,
  RESET_PASSWORD_PATH,
  COURSES_PATH,
  SETTINGS_PATH,
  MENTORS_PATH,
} from './pages/routes';
import PrivateLayout from './components/Layouts/PrivateLayout';
import MyCourses from './pages/Private/MyCourses';
import Courses from './pages/Private/Courses';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ModalsProvider } from '@mantine/modals';
import classes from './App.module.css';
import SettingsPage from './pages/Private/Settings';
import Course from './pages/Private/Course';
import Lesson from './pages/Private/Lesson';
import Mentors from './pages/Private/Mentors';
import Resource from './pages/Private/Resource';
import CourseStudy from './pages/Private/CourseStudy';
import Leaders from './pages/Private/Leaders';

const router = createBrowserRouter([
  {
    path: '/',
    element: <PrivateLayout />,
    children: [
      {
        index: true,
        element: <MyCourses />,
      },
      {
        path: COURSES_PATH,
        children: [
          {
            index: true,
            element: <Courses />,
          },
          {
            path: ':courseId/resources/:resourceId',
            element: <Resource />,
          },
          {
            path: ':courseId/study-flows/:studyFlowId',
            children: [
              {
                index: true,
                element: <Course />,
              },
              {
                path: 'leaders',
                element: <Leaders />,
              },
              {
                path: 'study',
                element: <CourseStudy />,
              },
              {
                path: 'resources/:resourceId',
                element: <Resource />,
              },
              {
                path: 'blocks/:blockId/lessons/:lessonId',
                element: <Lesson />,
              },
            ],
          },
        ],
      },
      {
        path: MENTORS_PATH,
        element: <Mentors />,
      },
      {
        path: SETTINGS_PATH,
        element: <SettingsPage />,
      },
    ],
  },
  {
    path: SIGN_UP_PATH,
    element: <SignUp />,
  },
  {
    path: SING_IN_PATH,
    element: <SignIn />,
  },
  {
    path: FORGOT_PASSWORD_PATH,
    element: <ForgotPassword />,
  },
  {
    path: RESET_PASSWORD_PATH,
    element: <ResetPassword />,
  },
]);

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ModalsProvider
        modalProps={{ classNames: { overlay: classes.modalOverlay } }}
      >
        <RouterProvider router={router} />
      </ModalsProvider>
    </QueryClientProvider>
  );
}

export default App;
