export const ROOT_PATH = '/';

export const SIGN_UP_PATH = '/sign-up';
export const SING_IN_PATH = '/sign-in';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const RESET_PASSWORD_PATH = '/reset-password/reset/:resetId';

export const STUDY_FLOWS_PATH = '/study-flows';

export const MENTORS_PATH = '/mentors';
export const SETTINGS_PATH = '/settings';

export const STUDY_GUIDE_PATH = '/study-guide';
export const MATERIALS_PATH = '/materials';
export const ADDITIONAL_MATERIALS_PATH = '/additional-materials';

export const COURSES_PATH = '/courses';

export const COURSE_PATH = '/courses/:courseId/study-flows/:studyFlowId/';
export const COURSE_STUDY_PATH =
  '/courses/:courseId/study-flows/:studyFlowId/study';
export const COURSE_LEADERS_PATH =
  '/courses/:courseId/study-flows/:studyFlowId/leaders';
export const COURSE_RESOURCE_PATH =
  '/courses/:courseId/study-flows/:studyFlowId/resources/:resourceId';
export const COURSE_LESSON_PATH =
  '/courses/:courseId/study-flows/:studyFlowId/blocks/:blockId/lessons/:lessonId';
