import { SING_IN_PATH } from '../pages/routes';
import { logOut } from './auth';

const apiUrl = process.env.REACT_APP_API_URL;

export enum ReqMethod {
  POST = 'POST',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
  GET = 'GET',
  PUT = 'PUT',
}

type ResponseError = {
  message: string;
  error: string;
  statusCode: number;
};

export const fetchHandler = async (
  url: string,
  params: Omit<RequestInit, 'body' | 'method'> & {
    body?: Record<string, any> | FormData;
    method: ReqMethod;
  },
  isPublicRoute?: boolean,
) => {
  try {
    const isFormData = params.body instanceof FormData;
    const response = await fetch(`${apiUrl}${url}`, {
      method: params.method,
      credentials: 'include',
      mode: 'cors',
      headers: isFormData
        ? undefined
        : {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
      body: params.body
        ? isFormData
          ? (params.body as FormData)
          : JSON.stringify(params.body)
        : params.body,
    });

    if (!response.ok) {
      const error = (await response.json()) as ResponseError;
      if (!isPublicRoute && [401].includes(error.statusCode)) {
        window.location.pathname = SING_IN_PATH;
        await logOut();
      }

      throw error;
    }

    if ([ReqMethod.GET, ReqMethod.POST].includes(params.method)) {
      if (response.body) {
        const json = await response.json().catch(() => null);
        if (json) {
          return json;
        }
      }
    }
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const fetchFile = async (
  url: string,
  filePath: string,
): Promise<Blob> => {
  const res = await fetch(`${apiUrl}${url}`, {
    method: ReqMethod.POST,
    credentials: 'include',
    mode: 'cors',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ filePath }),
  });
  const blob = await res.blob();
  return blob;
};
