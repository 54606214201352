import { Box, Button, Flex, Text, Title } from '@mantine/core';
import { generatePath, useNavigate } from 'react-router-dom';
import { getUserStudyFlows } from '../../../api/users';
import { useQuery } from '@tanstack/react-query';
import PageContainer from '../../../components/PageContainer';
import classes from './MyCourses.module.css';
import Card from '../../../components/Card';
import { StudyFlow } from '../../../api/studyFlows';
import { Course } from '../../../api/courses';
import { bodyTextColorHex } from '../../../theme';
import { COURSE_STUDY_PATH } from '../../routes';
import useUser from '../../../hooks/useUser';

type StudyFlowWithCourse = StudyFlow & { course: Course };

const RootPage = () => {
  const navigate = useNavigate();

  const { user } = useUser();
  const { data: userStudyFlows } = useQuery({
    queryKey: ['userStudyFlows'],
    queryFn: () => getUserStudyFlows(user._id),
  });

  const studyFlowsWithCourse = userStudyFlows?.filter(
    (studyFlow) => !!studyFlow?.course,
  ) as StudyFlowWithCourse[];
  return (
    <PageContainer title={`Привіт, ${user.firstName}`}>
      <div className={classes.pageContent}>
        <Flex mb="24px" pb="24px" className={classes.header}>
          <Title order={5} fz={20} fw="normal">
            Мої Курси
          </Title>
        </Flex>
        <Box
          className={!userStudyFlows?.length ? classes.emptyList : classes.list}
        >
          {studyFlowsWithCourse?.length ? (
            studyFlowsWithCourse?.map((studyFlow) => (
              <Card
                title={studyFlow?.course.name}
                subtitle="Курс"
                image={studyFlow?.course.image}
                button={{
                  label: 'Перейти до курсу',
                  onClick: () =>
                    navigate(
                      generatePath(COURSE_STUDY_PATH, {
                        studyFlowId: studyFlow?._id,
                        courseId: studyFlow?.course._id,
                      }),
                    ),
                }}
                p={12}
                w={248}
              >
                <Text mb="40px" fz={12}>
                  {studyFlow?.name}
                </Text>
              </Card>
            ))
          ) : (
            <>
              <Title
                order={4}
                fz={24}
                fw="normal"
                c={bodyTextColorHex}
                mb={48}
                mt={70}
                ta="center"
              >
                У вас немає придбаних курсів
              </Title>
              <Button h={47}>Купити курс</Button>
            </>
          )}
        </Box>
      </div>
    </PageContainer>
  );
};

export default RootPage;
