import { Image } from '@mantine/core';
import PageContainer from '../../../components/PageContainer';
import classes from './MyCourses.module.css';
import TechPartLogo from '../../../assets/images/TechPartLogo.jpeg';
import FinancePartLogo from '../../../assets/images/FinancePartLogo.jpeg';
import TechPartContent from './components/TechPartContent';
import FinancePartContent from './components/FinancePartContent';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getCourseResource } from '../../../api/courses';
import RichTextContent from '../../../components/RichTextContent';

const Resource = () => {
  const { studyFlowId, resourceId } = useParams();

  const { data: resource, isLoading } = useQuery({
    queryKey: ['resource', studyFlowId, resourceId],
    queryFn: async () =>
      studyFlowId && resourceId
        ? getCourseResource(studyFlowId, resourceId)
        : undefined,
  });
  if (isLoading) {
    return null;
  }
  return (
    <PageContainer title={resource?.title || ''}>
      <div className={classes.pageContent}>
        <RichTextContent content={resource?.text || ''} />
        {/* <Image src={TechPartLogo} className={classes.logo} />
        <div className={classes.content}>
          <TechPartContent />

        </div>
        <Image src={FinancePartLogo} className={classes.logo} />
        <div className={classes.content}>
          <FinancePartContent />
        </div> */}
      </div>
    </PageContainer>
  );
};

export default Resource;
