import { Image } from '@mantine/core';
import classes from './Navbar.module.css';
import { LinksGroup } from '../NavbarLinksGroup';
import {
  COURSES_PATH,
  COURSE_LEADERS_PATH,
  COURSE_LESSON_PATH,
  COURSE_PATH,
  COURSE_STUDY_PATH,
  MENTORS_PATH,
  ROOT_PATH,
  SETTINGS_PATH,
} from '../../pages/routes';
import LogoRed from '../../assets/images/LogoRed.png';
import { ReactComponent as RootIcon } from '../../assets/icons/navbar/RootIcon.svg';
import { ReactComponent as CoursesIcon } from '../../assets/icons/navbar/CoursesIcon.svg';
import { ReactComponent as MentorsIcon } from '../../assets/icons/navbar/MentorsIcon.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/navbar/SettingsIcon.svg';
import { ReactComponent as StudyIcon } from '../../assets/icons/navbar/StudyIcon.svg';
import clsx from 'clsx';
import {
  generatePath,
  matchPath,
  useLocation,
  useParams,
} from 'react-router-dom';
import { useMemo } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import MobileDropdown from './MobileDropdown';
import { NavItem } from './types';
import coursePathes from '../../constants/coursePathes';
import { useQuery } from '@tanstack/react-query';
import { getCourseResources } from '../../api/courses';
import { User } from '../../api/users';
import UserIcon from '../UserIcon';

function Navbar({ user }: { user: User }) {
  const { studyFlowId, courseId } = useParams();

  const { data: resources } = useQuery({
    queryKey: ['resources', studyFlowId],
    queryFn: () => (studyFlowId ? getCourseResources(studyFlowId) : undefined),
  });

  const isMobile = useMediaQuery('(max-width: 48em');

  const location = useLocation();
  const showStudyNavItem = coursePathes.some((to) =>
    matchPath(to, location.pathname),
  );
  const isSmallNavbar =
    !!matchPath(COURSE_LESSON_PATH, window.location.pathname) && !isMobile;

  const navItems = useMemo(
    () => [
      { label: 'Мої Курси', icon: <RootIcon />, to: ROOT_PATH },
      { label: 'Всі курси', icon: <CoursesIcon />, to: COURSES_PATH },
      {
        label: 'Навчання',
        icon: <StudyIcon />,
        active: true,
        hidden: !showStudyNavItem,
        links: [
          {
            label: 'Загальне',
            link: generatePath(COURSE_PATH, {
              studyFlowId: studyFlowId || '',
              courseId: courseId || '',
            }),
          },
          {
            label: 'Курс',
            link: generatePath(COURSE_STUDY_PATH, {
              studyFlowId: studyFlowId || '',
              courseId: courseId || '',
            }),
          },
          {
            label: 'Лідери',
            link: generatePath(COURSE_LEADERS_PATH, {
              studyFlowId: studyFlowId || '',
              courseId: courseId || '',
            }),
            hidden: true,
          },
          {
            label: 'Урок',
            link: COURSE_LESSON_PATH,
            hidden: true,
          },
          ...(resources?.map((resource) => ({
            label: resource.title,
            link: `/courses/${courseId}/study-flows/${studyFlowId}/resources/${resource._id}`,
          })) || []),
        ],
      },
      { label: 'Ментори', icon: <MentorsIcon />, to: MENTORS_PATH },
      { label: 'Налаштування', icon: <SettingsIcon />, to: SETTINGS_PATH },
    ],
    [showStudyNavItem, resources],
  ) as NavItem[];

  const links = navItems.map((item) => (
    <LinksGroup {...item} key={item.label} showStudyNavItem={isSmallNavbar} />
  ));

  return (
    <nav className={clsx(classes.navbar, isSmallNavbar && classes.smallNavbar)}>
      <div className={clsx(classes.header, isMobile && classes.headerMobile)}>
        <Image
          className={clsx(
            classes.logo,
            (isSmallNavbar || isMobile) && classes.smallLogo,
          )}
          src={LogoRed}
          fit="contain"
        />
        {isMobile && <UserIcon avatar={user.avatar} />}
      </div>
      {isMobile ? (
        <MobileDropdown links={links} navItems={navItems} />
      ) : (
        <div className={classes.linksInner}>{links}</div>
      )}
    </nav>
  );
}

export default Navbar;
