import {
  Box,
  FileButton,
  Flex,
  Image,
  Text,
  Title,
  UnstyledButton,
} from '@mantine/core';
import PageContainer from '../../../components/PageContainer';
import classes from './Settings.module.css';
import SettingRow from './SettingRow';
import ResetPasswordForm from './Form';
import useUser from '../../../hooks/useUser';
import EmptyUser from '../../../assets/images/EmptyUser.jpeg';
import { ReactComponent as UploadAvatar } from '../../../assets/icons/UploadAvatar.svg';
import { useState } from 'react';
import { FIVE_MB } from '../Lesson/components/HomeWork';
import { updateUserAvatar } from '../../../api/users';

const SettingsPage = () => {
  const { user, refetchUser } = useUser();
  const [error, setError] = useState<string>('');

  return (
    <PageContainer title="Налаштування">
      <div className={classes.pageContent}>
        <Flex mb="30px">
          <FileButton
            accept="image/jpeg, image/jpg, image/png"
            onChange={async (uploadedFile) => {
              setError('');
              if (!uploadedFile) {
                return;
              }
              if (uploadedFile?.size > FIVE_MB) {
                setError('Розмір файлу має бути меньше 5мб');
                return;
              }
              const ext = uploadedFile.name.split('.').pop();
              if (!['png', 'jpeg', 'jpg'].includes(ext || '')) {
                setError('Тип файлу має бути: JPEG, JPG, PNG');
                return;
              }
              let formData = new FormData();
              formData.append('avatar', uploadedFile);
              await updateUserAvatar(formData);
              await refetchUser();
            }}
          >
            {(props) => (
              <UnstyledButton {...props} mr="24px" pos="relative">
                <Image
                  src={user.avatar || EmptyUser}
                  alt="img"
                  className={classes.avatar}
                />
                <Box className={classes.uploadAvatarIcon}>
                  <UploadAvatar />
                </Box>
              </UnstyledButton>
            )}
          </FileButton>
          <Flex ml="15px" direction="column" justify="center">
            <Title fz="14px" fw="bolder" lh="21px" mb="4px">
              Завантажити фото
            </Title>
            <Title fz="12px" fw="300" c="#84818A">
              300x300 і максимум 5 MB <br />
              Тип файлу: JPEG, JPG, PNG
            </Title>
          </Flex>
        </Flex>
        {error && (
          <Text mt="sm" mb="md" className={classes.errorField} c="red">
            {error}
          </Text>
        )}
        <Flex wrap="wrap" justify="space-between">
          <SettingRow
            className={classes.halfWidthSetting}
            label="Ім`я"
            value={user?.firstName}
          />
          <SettingRow
            className={classes.halfWidthSetting}
            label="Прізвище"
            value={user?.lastName}
          />
          <SettingRow
            className={classes.halfWidthSetting}
            label="Telegram"
            value={user?.telegramName}
          />
          <SettingRow
            className={classes.halfWidthSetting}
            label="Discord"
            value={user?.discordName}
          />
          <SettingRow
            w="100%"
            label="Номер телефону"
            value={user?.phoneNumber}
          />
        </Flex>
      </div>
      <div className={classes.pageContent}>
        <Box>
          <ResetPasswordForm />
        </Box>
      </div>
    </PageContainer>
  );
};

export default SettingsPage;
