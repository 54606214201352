import { Outlet } from 'react-router-dom';
import { Flex } from '@mantine/core';
import Navbar from '../../Navbar';
import { getMe, User } from '../../../api/users';
import { useQuery } from '@tanstack/react-query';
import { useMediaQuery } from '@mantine/hooks';

export type PrivateOutletContext = {
  user: User;
  refetchUser: () => Promise<any>;
};

function PrivateLayout() {
  const isMobile = useMediaQuery('(max-width: 48em');

  const {
    data: user,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['me'],
    queryFn: () => getMe(),
  });

  if (!user) {
    if (isLoading) {
      return null;
    }
    return null;
  }
  return (
    <Flex p="20px" mih="100vh" direction={isMobile ? 'column' : undefined}>
      <Navbar user={user} />
      <Outlet
        context={{ user, refetchUser: refetch } as PrivateOutletContext}
      />
    </Flex>
  );
}

export default PrivateLayout;
