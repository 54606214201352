import { FC } from 'react';
import { Image, BoxProps, Popover, Text } from '@mantine/core';
import classes from './UserIcon.module.css';
import EmptyUser from '../../assets/images/EmptyUser.jpeg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/LogoutIcon.svg';
import { SING_IN_PATH } from '../../pages/routes';
import { logOut } from '../../api/auth';

const UserIcon: FC<BoxProps & { avatar?: string }> = ({ avatar, ...props }) => {
  return (
    <Popover width={168} trapFocus position="bottom">
      <Popover.Target>
        <Image
          src={avatar || EmptyUser}
          alt="img"
          className={classes.image}
          {...props}
        />
      </Popover.Target>
      <Popover.Dropdown
        className={classes.dropdown}
        onClick={async () => {
          await logOut();
          window.location.href = SING_IN_PATH;
        }}
      >
        <LogoutIcon /> <Text ml={16}>Вихід</Text>
      </Popover.Dropdown>
    </Popover>
  );
};

export default UserIcon;
