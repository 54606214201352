import { Box, Button, Flex, Image, Text, Title } from '@mantine/core';
import { UserRole, getUsers } from '../../../api/users';
import { useQuery } from '@tanstack/react-query';
import PageContainer from '../../../components/PageContainer';
import classes from './MyCourses.module.css';
import { bodyTextColorHex } from '../../../theme';
import MentorImage from '../../../assets/images/MentorImage.jpeg';
import { ReactComponent as DiscordIcon } from '../../../assets/icons/DiscordIcon.svg';

const RootPage = () => {
  const { data: mentors, refetch } = useQuery({
    queryKey: ['mentors'],
    queryFn: () => getUsers(UserRole.MENTOR),
  });

  return (
    <PageContainer title="Користувачі">
      <div className={classes.pageContent}>
        <Box className={!mentors?.length ? classes.emptyList : classes.list}>
          {mentors?.length ? (
            mentors.map((mentor) => (
              <Flex className={classes.mentorCard}>
                <Image
                  src={mentor?.avatar || MentorImage}
                  className={classes.image}
                />
                <Text mb="12px" fz={16} fw="bolder">
                  {mentor.firstName} {mentor.lastName}
                </Text>
                <Text mb="12px" fz={12} fw="nirmal">
                  @{mentor.discordName}
                </Text>
                <Button
                  component="a"
                  className={classes.button}
                  leftSection={<DiscordIcon />}
                  href="https://discord.com/login"
                  target="_blank"
                >
                  Discord
                </Button>
              </Flex>
            ))
          ) : (
            <>
              <Title
                order={4}
                fz={24}
                fw="normal"
                c={bodyTextColorHex}
                mb={48}
                mt={70}
                ta="center"
              >
                Немає менторів
              </Title>
            </>
          )}
        </Box>
      </div>
    </PageContainer>
  );
};

export default RootPage;
